import React from 'react';
import { FaFacebookSquare, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
 
    return(
        <div className='footerClass bg-[#0f0f0f] text-white'>
            <div className='w-full max-w-[95%] m-auto p-4 md:pt-8 grid grid-cols-1 md:grid-cols-6'>
                <div className='w-full max-w-[95%] text-xs font-bold mb-8 col-span-4 md:border-r-2 md:border-blue-200'>
                    <p className='font-bold text-lg pb-3 underline'>{t("our_services")}</p>
                    <div className='grid grid-cols-1 md:grid-cols-6'>                
                        <div className='col-span-3 sm:col-span-2'>
                            <ul>                            
                                <li className='pb-2'>
                                    <NavLink to="services/#webdev">{t("web_development")}</NavLink>
                                </li>
                                <li className='pb-2'>
                                    <NavLink to="services/#cloud">{t("cloud_services")}</NavLink>
                                </li>
                                <li className='pb-2'>
                                    <NavLink to="services/#chatbot">{t("development_of_chatbots")}</NavLink>
                                </li>                                
                            </ul>
                        </div>  
                        <div className='col-span-3 sm:col-span-2'>
                            <ul>     
                                <li className='pb-2'>
                                    <NavLink to="services/#mobile">{t("mobile_application_development")}</NavLink>
                                </li>
                                <li className='pb-2'>
                                    <NavLink to="services/#desktop">{t("desktop_application_development")}</NavLink>
                                </li>                       
                                <li className='pb-2'>
                                    <NavLink to="services/#agile">{t("technical_and_agile")}</NavLink>
                                </li>                                                               
                            </ul>
                        </div> 
                        
                    </div>
                </div>
                
                <div className='grid grid-cols-4 col-span-2 text-xs font-bold mb-8' style = {{ marginRight: "5vw"}}>                    
                    <div className='col-span-3'>
                        <p className='font-bold text-lg pb-3 underline'>{t("contact")}</p>
                        {/* <p className='pb-1'>XXXX, XXXXX-12345 </p> */}
                        {/* <p className='pb-1'>XXXXX-12345</p> */}
                        {/* <p className='pb-1'>XXXXX, XXXXX</p> */}
                        
                        <p className='pb-1'>contact@astreutech.com</p>                    
                    </div>
                    <div className='col-span-1 grid justify-items-end items-center' >
                        <ul className='flex gap-4 md:flex-col md:gap-0'> 
                        <li className='pb-4'>
                                <a href="https://api.whatsapp.com/send?phone=5511951799970"><FaWhatsapp size={18} /></a>
                            </li>                           
                            <li className='pb-4' >
                                <a href="https://www.linkedin.com/company/astreutech/"><FaLinkedin size={18} /> </a>
                            </li>
                            <li className='pb-4'>
                                <a href="https://www.instagram.com/astreutech?igsh=MWk4amp4c2xvcnl3bA=="><GrInstagram size={18} /></a>
                            </li>
                            <li className='pb-4'>
                                <a href="https://www.facebook.com/profile.php?id=61559684325029"><FaFacebookSquare size={18} /></a>
                            </li>
                        </ul>
                    </div> 
                    <div className='col-span-3'>
                        <p className='pb-1'><span className='font-semibold'>CNPJ</span> 55.480.141/0001-40</p>                    
                    </div>                   
                </div>                        
            </div>        
            <div className='w-full'>
                <p className='text-center pb-4 text-sm'>AstreuTech &#169; {currentYear} | {t("all_rights")}</p>                           
                <p className='text-center pb-4 text-sm'>{t("site_developed")} </p> 
            </div>    
            
        </div>        
    )
}

export default Footer;