import React from 'react';
import { FaWhatsapp } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return(
        <div id="contact" className='contactClass w-full md:p-8 grid md:grid-cols-2 justify-items-center lg:gap-40'>                         
            <div className='w-full max-w-[600px] mt-16 md:mt-2 md:p-2 flex flex-col gap-6 items-center'>                
                <h1 className='text-2xl'>{t("contact")}</h1>
                <a href = "https://wa.link/hwzl9y">
                <div className='w-[300px] flex gap-14 justify-start items-center bg-[#25D366] p-4 mb-4 ' style = {{boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.25)'}}>
                    
                    <FaWhatsapp size={40} />
                    <div className='flex flex-col'>
                        <p className='text-xl font-bold text-center'>WhatsApp</p>
                        <p>+55 11 95179 9970</p>
                    </div>                    
                </div>
                </a>                
                
                <div className='w-[300px] flex gap-6 justify-start items-center bg-[#dddddd] p-4 mb-4' style = {{boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.25)'}}>
                    <FiMail size={40}/>
                    <div>
                        <p className='text-xl font-bold text-center'>Email</p>
                        <p>contact@astreutech.com</p>
                    </div>                    
                </div>
            </div>    

            <div className='w-full max-w-[500px] py-20 md:p-2'>
                <h1 className='text-2xl pb-4 text-center'>{t("contact_us")}</h1>                                        
                <form className='flex flex-col mx-auto w-[85%] md:w-full' action="https://getform.io/f/pagxoenb" method="post">
                    <input className='p-4 rounded-lg border-2 border-[#0f0f0f]' type="text" name="fullname" placeholder="Seu Nome"/>
                    <input className='my-2 p-4 rounded-lg border-2 border-[#0f0f0f]' type="email" name="email" placeholder="Seu Email"/>
                    <textarea className='w-full p-4 rounded-lg border-2 border-[#0f0f0f]' rows="10" name="message" placeholder="Sua mensagem"></textarea>
                    <button className='text-white text-xl font-bold bg-[#06283d] border-2 border-[#06283d] my-2 px-4 py-3 rounded-md text-md hover:bg-[#191a2b]'>Enviar</button>
                </form>
            </div>

        </div>
    )
}

export default Contact;