import React from 'react';
import { useTranslation } from 'react-i18next';

const Career = () => {
    const { t } = useTranslation();
    return(
<div>
            <div className='careerClass w-full h-[500px]'> 
                <div className='px-12 py-[245px] flex flex-col flex-wrap justify-center text-gray-300 md:w-[525px]'>
                    <h1 className='text-4xl font-black py-2 md:text-5xl'>{t("job_vacancies")}</h1>
                    <p className='w-[300px]  text-md font-black md:text-lg md:w-full'>
                        {t("collaborative_work_environment")}
                        </p>
                </div>                
            </div>
            <div className='flex flex-col-reverse md:flex-row my-12'>
                <div className='px-12 py-4 md:w-[50%]'>
                    <h1 className='text-[1.8rem] font-black sm:text-[2rem] md:text-[2rem]'>{t("job_vacancies")}</h1>
                    <p className='text-sm py-2 sm:text-lg md:text-xl'> {t("no_open_positions")}</p>
                    <p className='text-sm py-2 sm:text-lg md:text-lg'> {t("we_currently_have_no_positions_but")} </p>
                    <p className='text-sm py-2 sm:text-lg md:text-lg font-bold italic underline'> contact@astreutech.com </p>
                </div>
                <div className='px-12 py-4 md:w-[50%]'>
                    <h1 className='text-[1.8rem] font-black sm:text-[2rem] md:text-[2rem]'>{t("intership_vacancies")}</h1>
                    <p className='text-sm py-2 sm:text-lg md:text-xl text-left'> 
                        {t("everyone_has_to_start")}
                        </p>                    
                    <p className='text-sm py-2 sm:text-lg md:text-lg italic'> {t("no_internship_vacancies_open")} </p>
                </div>
            </div>                      
            
        </div>
    )
}

export default Career;