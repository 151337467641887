import React from 'react';
import WebSVG from '../assets/coding.svg'
import CloudSVG from '../assets/cloud.svg'
import ITStuffSVG from '../assets/it-stuff.svg'
import MobileAppSVG from '../assets/mobileApp.svg'
import DesktopAppSVG from '../assets/desktopApp.svg'
import ChatBotPNG from '../assets/chatbot.png'
import OurProcess from '../components/OurProcess';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    return(
        <div>

            <div className='servicesClass w-full h-[500px]'> 
                <div className='px-12 py-[245px] flex flex-col flex-wrap justify-center text-gray-300 md:w-[700px]'>
                    <h1 className='text-4xl font-black py-2 md:text-5xl'>{t("services")}</h1>
                    <p className='w-[300px] text-md font-black md:text-lg md:w-full'>
                        {t("we_are_your_reliable_software")}</p>
                </div>                
            </div>

            <OurProcess/>
            
            <section id="webdev" className='flex flex-col-reverse items-center md:flex-row my-12'>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("web_development")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                        {t("we_provide_web_solutions")}</p>
                </div>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={WebSVG} alt="Desenvolvimento-Web" />
                </div>
            </section> 
            
                      

            <section id="cloud" className='flex flex-col items-center md:flex-row my-12'>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={CloudSVG} alt="Serviços-em-Cloud" />
                </div>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("cloud_services")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                        {t("we_develop_solutions_enterily_in_the_cloud")}</p>
                </div>                
            </section>

            <section id="chatbot" className='flex flex-col-reverse items-center md:flex-row my-12'>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("development_of_chatbots")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                        {t("we_develop_chatbots")}</p>
                </div>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={ChatBotPNG} alt="Desenvolvimento-de-ChatBots" />
                </div>
            </section>

            <section id="mobile" className='flex flex-col items-center md:flex-row my-12'>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={MobileAppSVG} alt="Desenvolvimento-de-Aplicativos-Mobile" />
                </div>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("mobile_application_development")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                    {t("we_develop_high_quality")}</p>
                </div>                
            </section>

            <section id="desktop" className='flex flex-col-reverse items-center md:flex-row my-12'>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("desktop_application_development")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                    {t("even_with_the_migration")}
                    </p>
                </div>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={DesktopAppSVG} alt="Desenvolvimento-de-Aplicativos-Desktops" />
                </div>
            </section>

            <section id="agile" className='flex flex-col items-center md:flex-row my-12'>
                <div className='md:w-[40%]'>
                    <img className="w-[125px] mx-auto my-2 sm:w-[180px] md:w-[200px]" src={ITStuffSVG} alt="Consultoria-Técnica-e-Ágil" />
                </div>
                <div className='px-12 py-4 text-center md:w-[60%]'>
                    <h1 className='font-black text-[1.3rem] sm:text-[2rem] md:text-[2.2rem]'>{t("technical_and_agile")}</h1>
                    <p className='py-2 text-sm sm:text-lg text-left'> 
                    {t("our_team_of_experts")}                    
                    </p>
                </div>                
            </section>
            
        </div>
    )
}

export default Services;