import React from "react";
import { FaNodeJs } from "react-icons/fa6";
import { FaReact } from "react-icons/fa";
import { FaJava } from "react-icons/fa";
import { BiLogoSpringBoot } from "react-icons/bi";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { SiMongodb } from "react-icons/si";
import { IoLogoElectron } from "react-icons/io5";
import { RiFlutterLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

const Techstacks = () => {
    const { t } = useTranslation();
    const iconsList = [
        {
            icon : <FaNodeJs className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12" />,
            text : 'Nodejs',
        },
        {
            icon : <FaReact className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12" />,
            text : 'React',
        },
        {
            icon : <FaJava  className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12"  />,
            text : 'Java',
        },
        {
            icon : <BiLogoSpringBoot className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12"  />,
            text : 'Springboot',
        },
        {
            icon :  <BsDatabaseFillCheck className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12" />,
            text : 'SQL',
        },
        {
            icon : <SiMongodb className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12"  />,
            text : 'MongoDB',
        },
        {
            icon :  <IoLogoElectron className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12"  />,
            text : 'Electron',
        },
        {
            icon : <RiFlutterLine className="w-8 h-8 mx-6 my-2 sm:w-12 sm:h-12"  />,
            text : 'Flutter',
        }
    ]

    return (
        <div className="w-full text-green-200 flex flex-col justify-center items-center pb-12">
            <h1 className="text-[1.6rem] text-[#D0D7D9] m-8 text-center font-bold sm:text-[2rem] md:text-[2.2rem]">{t("we_use_best_tech")}</h1>
            <div className="flex flex-wrap justify-around text-[#f5f5f5] w-full max-w-[900] px-2 py-2">                
                
                {iconsList.map((icon) => {
                    return (
                        <div className="flex flex-col items-center">                     
                            {icon.icon}
                            <span>{icon.text}</span>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default Techstacks;