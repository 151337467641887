import React from 'react';
import Hero from "../components/Hero";
import Techstacks from "../components/Techstacks";

const Home = () => {
    return(
        <div id='main'>

            <Hero/> 
                       
            <Techstacks/> 

        </div>
    )
}

export default Home;