import React from 'react';
import DevTesting from '../assets/code-svgrepo-com.svg';
import EvaluationScope from '../assets/graph-svgrepo-com.svg';
import FirstInteraction from '../assets/handshake-deal-svgrepo-com.svg';
import LaunchSupport from '../assets/support-svgrepo-com.svg';
import ProcessCard from './ProcessCard';
import { useTranslation } from 'react-i18next';

const OurProcess = () => {

    const { t } = useTranslation();
    
    return(
        <div className="w-full bg-white text-[#2c0f37] font-black border-2 py-12">
            <div className='flex flex-col justify-center items-center px-4 md:px-2'>
                <div className='mx-2 px-2 text-center sm:px-20'>
                    <h1 className='text-[1.8rem] font-black m-1 py-1 text-center sm:text-[2rem] md:text-[2.2rem]'>{t("we_take_care")}</h1>
                    <p className='text-sm px-2 md:text-lg md:px-24 md:m-6 text-left'>
                        {t("clarity_transparency_integrity")}
                    </p>
                </div>                
                <div className='w-full grid justify-items-center sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 mb-8 sm:px-12'>                
                    <ProcessCard 
                        src={FirstInteraction} 
                        para={t("share_your_vision")}
                        back={t("you_share_your_vision")}
                    >
                    </ProcessCard>

                    <ProcessCard
                        src={EvaluationScope}
                        para={t("get_a_roadmap")}
                        back={t("we_present_a_clear_roadmap")}
                        >
                    </ProcessCard>

                    <ProcessCard
                        src={DevTesting}
                        para={t("development_and_testing")}
                        back={t("developers_work_closely")}
                        >
                    </ProcessCard>

                    <ProcessCard
                        src={LaunchSupport}
                        para={t("post_launch_support")}
                        back={t("after_delivery")
                        }>
                    </ProcessCard>                    
                </div>
            </div>            
        </div>
    )
}

export default OurProcess;