import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import Logo from '../assets/logo-white.svg'
import { useTranslation } from 'react-i18next';
import BrazilFlag from '../locales/pt/brazil-flag.svg';
import UsaFlag from '../locales/en/usa-flag.svg';

const Navbar = () =>{

    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const [nav, setNav] = useState(false);

    const activeLink = "text-gray-400"
    
    const handleNav = () => {
        setNav(!nav)
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return(
        <div>
            <div id="navbar" className="flex justify-between items-center h-24 w-full mx-auto px-4 text-white">
                <h1 className="flex w-full text-green-600 text-2xl font-bold uppercase m-4">
                    <div className="flex items-center justify-left">
                        <NavLink to="/">
                            <img className="w-[180px] font px-4 my-4 sm:w-[200px] md:w-[230px] items-start -mx-8" src={Logo} alt="logo" />
                        </NavLink>   
                        <div className="md:hidden p-2 items-start -mx-12">
                            <span className="flex">
                                <img src={BrazilFlag} alt="Portuguese" onClick={() => changeLanguage('pt')} className="w-5 h-6 cursor-pointer mx-1" />
                                <img src={UsaFlag} alt="English" onClick={() => changeLanguage('en')} className="w-5 h-6 cursor-pointer mx-1" />                
                            </span>
                        </div>                 
                    </div>
                </h1>
                <ul className="hidden md:flex font-bold">
                    <li className="p-4">
                        <NavLink                         
                            to="/"    
                            className={({ isActive }) => isActive ? activeLink : ''}                    
                        >
                            {t("home")}
                        </NavLink>
                    </li>
                    <li className="p-4">
                        <NavLink                         
                            to="/about"                        
                            className={({ isActive }) => isActive ? activeLink : ''}
                        >
                            {t("about")}
                        </NavLink>
                    </li>
                    <li className="p-4">
                        <NavLink                         
                            to="/services"                        
                            className={({ isActive }) => isActive ? activeLink : ''}
                        >
                            {t("services")}
                        </NavLink>
                    </li>
                    <li className="p-4">
                        <NavLink                         
                            to="/career"                        
                            className={({ isActive }) => isActive ? activeLink : ''}
                        >
                            {t("career")}
                        </NavLink>
                    </li>                
                    <li className="p-4">
                        <NavLink
                            to="/contact"                        
                            className={({ isActive }) => isActive ? activeLink : ''}
                        >
                            {t("contact")}
                        </NavLink>
                    </li>                   
                    <li className="p-4 px-8">
                        <span className="flex justify-center">
                            <img src={BrazilFlag} alt="Portuguese" onClick={() => changeLanguage('pt')} className="w-5 h-6 cursor-pointer mx-1" />
                            <img src={UsaFlag} alt="English" onClick={() => changeLanguage('en')} className="w-5 h-6 cursor-pointer mx-2" />                
                        </span>
                    </li>                    
                </ul>
                <div onClick={handleNav} className="block md:hidden">
                    {nav ? <AiOutlineClose size={20}/>: <AiOutlineMenu size={20}/>}                
                </div>

                <div className={nav ? "fixed left-0 top-0 w-[85%] h-[96%] z-20 border-r border-r-gray-900 bg-[#000] ease-in-out duration-500" : "fixed left-[-100%]"}>                    
                    <img className="w-[150px] mx-4 my-8 items-start" src={Logo} alt="logo" />
                    
                    <ul className="uppercase p-4">
                        <li className="p-4 border-b border-gray-600">
                            <NavLink
                                to="/"
                                onClick={handleNav}
                            >
                                {t("home")}
                            </NavLink>
                        </li>
                        <li className="p-4 border-b border-gray-600">
                            <NavLink
                                to="/about"
                                onClick={handleNav}
                            >
                                {t("about")}
                            </NavLink>
                        </li>
                        <li className="p-4 border-b border-gray-600">
                            <NavLink
                                to="/services"
                                onClick={handleNav}
                            >
                                {t("services")}
                            </NavLink>
                        </li>
                        <li className="p-4 border-b border-gray-600">
                            <NavLink
                                to="/career"
                                onClick={handleNav}
                            >
                                {t("career")}
                            </NavLink>
                        </li>
                        <li className="p-4">
                            <NavLink
                                to="/contact"
                                onClick={handleNav}
                            >
                                {t("contact")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>        
    )
}

export default Navbar;