import React from "react";
import { NavLink } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { useTranslation } from 'react-i18next';
 
const Hero = () => {

    const { t } = useTranslation();

    return(
        <div className="text-green-100 h-[80vh] md:h-[90vh]">
            <div className="max-w-[925px] w-full py-24 md:py-32 mx-auto text-center flex flex-col justify-center">                                
                <div className="md:flex flex-row justify-center items-center text-[#D0D7D9]">

                    <h1 className="font-bold py-4 px-2 sm:px-1 text-2xl sm:text-[42px]">{t("hero_title")} </h1>                    
                    <ReactTyped
                        className="font-bold py-4 px-2 text-2xl sm:text-[44px] text-[#044BD9]"
                        typeSpeed={110} 
                        backSpeed={130} 
                        onBegin={function noRefCheck(){}}
                        onComplete={function noRefCheck(){}}
                        onDestroy={function noRefCheck(){}}
                        onLastStringBackspaced={function noRefCheck(){}}
                        onReset={function noRefCheck(){}}
                        onStart={function noRefCheck(){}}
                        onStop={function noRefCheck(){}}
                        onStringTyped={function noRefCheck(){}}
                        onTypingPaused={function noRefCheck(){}}
                        onTypingResumed={function noRefCheck(){}}
                        strings={[
                            t("hero_subtitle_1"),
                            t("hero_subtitle_2"),                            
                        ]}                        
                        typedRef={function noRefCheck(){}}
                        loop                        
                    />
                </div>                                    
                <div className="text-left text-md sm:text-xl py-1 px-10 xl:px-[10px] text-[#D0D7D9]">
                <p>{t('hero_paragraph')}</p>
                </div>
                
                <button className="w-[160px] bg-gray-100 text-[#2c0f37] font-black border-[1px] border-[white] mt-8 sm:mt-16 text-sm px-2 py-2 mx-auto my-2 rounded-md sm:w-[145px] md:w-[250px] sm:text-lg md:text-xl hover:bg-[#0f0f0f] hover:text-gray-100 hover:transition duration-150">
                    <NavLink to="/about">{t("hero_button")}</NavLink>
                </button>
            </div>                                    
        </div>
    )
}
 
export default Hero;