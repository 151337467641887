import React from 'react';
import AboutImage from '../assets/about.svg';
// import LaunchSupport from '../assets/support-svgrepo-com.svg';
import ClientImage from '../assets/clientfirst.svg';
import CollaborationImage from '../assets/collaboration.svg';
import DedicationImage from '../assets/dedication.svg';
import DiversityImage from '../assets/diversity.svg';
import GrowthImage from '../assets/growth.svg';
import SmartImage from '../assets/smart.svg';
import { useTranslation } from 'react-i18next';

 
const About = () => {
    const { t } = useTranslation();
    return(
        <div>
            <div className='aboutClass w-full h-[500px]'> 
                <div className='px-12 py-[245px] flex flex-col flex-wrap justify-center text-gray-300 md:w-[700px]'>
                    <h1 className='text-4xl font-black py-2 md:text-5xl'>{t("aboutUs")}</h1>
                    <p className='w-[300px]  text-md font-black md:text-lg md:w-full'>{t("our_objective")}</p>
                </div>                
            </div>
            <div className='flex flex-col-reverse items-center md:flex-row my-12 text-sm sm:text-lg'>
                <div className='px-12 py-4 text-center md:w-[50%] lg:w-[60%]'>
                    <h1 className='m-2 text-[1.3rem] sm:text-[1.75rem] md:text-[1.95rem] font-black leading-9'>{t("about_our_work_way")}</h1>
                    <p className='py-2 text-left'> 
                    {t("astreuTech_is_a_trusted_company")}
                    </p>
                </div>
                <div className='md:w-[45%] lg:w-[35%]'>
                    <img className="w-[300px] mx-auto my-2 sm:w-[400px] md:w-[600px]" src={AboutImage} alt="Sobre-Nós" />
                </div>
            </div>
            <div className='grid my-12 text-sm sm:text-lg'>
                <div className='text-center'>
                    <h1 className='m-2 text-[1.3rem] font-black sm:text-[1.75rem] md:text-[1.95rem]'>{t("our_Mission_and_Values")}</h1>
                    <p className='px-8 py-2 md:px-[100px] lg:px-[270px]'>
                        {t("our_team_is_made")}
                    </p>
                </div>
                <div className='grid md:grid-cols-6 md:justify-items-center py-12'>
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={ClientImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("customer_first")}</span> - {t("with_our_customer_first")}</p>
                    </div>                    
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={CollaborationImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("collaboration")}</span> - {t("we_believe_in_collaboration")}</p>
                    </div>                    
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={DedicationImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("dedication")}</span> - {t("each_and_every_problem")}</p>
                    </div>
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={DiversityImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("diversity")}</span> - {t("in_our_company_the_only_distinction")}</p>
                    </div>                    
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={SmartImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("simple_and smart")}</span> - {t("we_find_simple_but_intelligent")}</p>
                    </div>
                    <div className='grid grid-cols-6 gap-3 px-4 py-4 md:block md:col-span-2 md:px-16'>
                        <img className='col-span-1 md:mx-auto w-16' src={GrowthImage} alt="icon"/>
                        <p className='col-span-5 text-left'> <span className='text-lg font-black'>{t("growth")}</span> - {t("we_believe_that_everyone")}</p>
                    </div>                    
                </div>
            </div>
            
        </div>        
    )
}

export default About;